import { useState } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import FormHelperText from '@mui/material/FormHelperText'

// Third-party Imports
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Icon } from '@iconify/react/dist/iconify.js'
import * as v from 'valibot';
import PhoneInput from 'react-phone-input-2'

// Hook Imports
import { useCompany } from '../../../hooks/useCompany'

// Component imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'

// Api Imports
import { sendQuoteDataReq } from '../../../service/api'

import calculatePrice from '../../../util/calculate_price'

const step5Schema = v.object({
    name: v.pipe(
        v.string(),
        v.trim(),
        v.minLength(1, "This field is required"),
    ),
    email: v.pipe(v.string(), v.minLength(1, "Invalid email address"), v.email("Invalid email address")),
    company_name: v.pipe(v.string()),
    phone_number: v.pipe(v.string()),
    referral_id: v.pipe(v.string()),
});

function CompanyFormStep5({ onSubmit, handleBack, title }) {

    // State
    const [loading, setLoading] = useState(false)

    // Hooks
    const { company, updateComapny } = useCompany()

    const {
        control: step5Control,
        handleSubmit: handleStep5Submit,
        formState: { errors: step5Errors }
    } = useForm({
        resolver: valibotResolver(step5Schema),
        defaultValues: {
            company_name: company.company_name,
            email: company.email,
            phone_number: company.phone_number,
            name: company.name,
            referral_id: company.referral_id
        }
    })

    const handleSubmit = async (data) => {
        try {
            let totalPrice = calculatePrice(company)
            setLoading(true)
            let quoteData = { ...company, email: data.email, name: data.name, phone_number: data.phone_number, referral_id: data.referral_id, total_price: totalPrice }
            updateComapny({ total_price: totalPrice })
            let res = await sendQuoteDataReq(quoteData)
            setLoading(false)
            onSubmit(data)
        } catch (err) {
            setLoading(false)
        }
    }

    return (
        <form key={5} onSubmit={handleStep5Submit(handleSubmit)}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography variant='h5' className='!text-textPrimary !font-bold'>{title}</Typography>
                    <div className='flex flex-col gap-4 mt-4'>
                        <Controller
                            name='name'
                            control={step5Control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    fullWidth
                                    label='Please enter your full name.'
                                    placeholder='Name'
                                    {...(step5Errors.name && { error: true, helperText: step5Errors.name.message })}
                                />
                            )}
                        />
                        <Controller
                            name='email'
                            control={step5Control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    fullWidth
                                    label='Please enter your email address.'
                                    placeholder='Email'
                                    {...(step5Errors.email && { error: true, helperText: step5Errors.email.message })}
                                />
                            )}
                        />
                        <Controller
                            name='company_name'
                            control={step5Control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    fullWidth
                                    label='Company name (optional)'
                                    placeholder='Company name'
                                />
                            )}
                        />
                        <Controller
                            name='phone_number'
                            control={step5Control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <div>
                                    <Typography variant='h6' className='!font-bold'>Phone</Typography>
                                    <PhoneInput
                                        value={field.value}
                                        onChange={phone => field.onChange(phone)}
                                        country={'gb'}
                                        containerClass={{ width: '100%' }}
                                        preferredCountries={['gb', 'tr']}
                                        containerStyle={{
                                            marginTop: '4px',
                                            marginBottom: '4px',
                                        }}
                                        inputStyle={{
                                            width: '100%',
                                            height: '37px',
                                            fontSize: '14px',
                                            paddingLeft: '48px',
                                            borderRadius: '5px',
                                        }}
                                        buttonStyle={{ borderRadius: '5px 0 0 5px' }}
                                        {...((step5Errors.phone_number) && {
                                            error: true,
                                            helperText: step5Errors?.phone_number?.message
                                        })}
                                    />
                                    {step5Errors.phone_number && <FormHelperText error>{step5Errors?.phonenumber?.message}</FormHelperText>}
                                </div>
                            )}
                        />
                        <Controller
                            name='referral_id'
                            control={step5Control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    fullWidth
                                    label='Please enter your referral code. (optional)'
                                    placeholder='Referral Code'
                                />
                            )}
                        />
                        <Typography variant='body1'>
                            By clicking the "Get a Quote" button, you agree and accept Sadler & Frost's <a className='no-underline text-primary' href="https://sadlerandfrost.com/privacy-policy" target='_blank'>Privacy Policy</a>. We will never share your details with any third-party.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} className='flex justify-between'>
                    <Button
                        variant='tonal'
                        onClick={handleBack}
                        color='secondary'
                        startIcon={<DirectionalIcon ltrIcon='tabler-arrow-left' rtlIcon='tabler-arrow-right' />}
                    >
                        Back
                    </Button>
                    <Button disabled={loading} variant='contained' type='submit' endIcon={<Icon icon='tabler-check' />}>
                        {loading && <CircularProgress className='mr-4' size={20} color='inherit' />}
                        Get a Quote
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default CompanyFormStep5